import React from 'react';
import { Link } from 'react-router-dom';
import './Upcoming.css';
import { useTheme } from '../ThemeContext/ThemeContext';
import SatanSpeaks from '../SatanSpeaks/SatanSpeaks';

const Upcoming = ({ todos, categories, handleHamburgerClick, handleTodoUpdate }) => { // Add categories to props
  const { theme } = useTheme();

  const handleDeleteDate = (date) => {
    const updatedTodos = { ...todos };
    delete updatedTodos[date];
    handleTodoUpdate(updatedTodos);
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateObj = new Date(dateString + 'T00:00:00');
    return dateObj.toLocaleDateString(undefined, options);
  };

  const datesWithTodos = Object.keys(todos).filter(date => date !== 'My Day' && date !== 'Graveyard' && !categories.some(cat => cat.name === date));
  const sortedDates = datesWithTodos.sort((a, b) => new Date(a) - new Date(b));

  return (
    <div className={`app-container ${theme}-theme`}>
      <div className="my-day-container"><h1 className="my-day">Upcoming</h1></div>
      <div className="todo-content-container">
        {sortedDates.map((date, index) => {
          const displayText = isNaN(new Date(date).getTime()) ? date : formatDate(date);
          const linkPath = isNaN(new Date(date).getTime()) ? `/category/${date}` : `/calendar/${date}`;
          return (
            <div key={index} className="upcoming-date">
              <Link to={linkPath} className="upcoming-date-link">
                {displayText} ({todos[date].length} todos)
              </Link>
              <button onClick={() => handleDeleteDate(date)} className="delete-date-button">X</button>
            </div>
          );
        })}
      </div>
      <footer className='footer-container'>
        <div className='hamburger-icon' onClick={handleHamburgerClick}>
          <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
        </div>
        <SatanSpeaks />
      </footer>
    </div>
  );
};

export default Upcoming;
