import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import DateComponent from './components/DateComponent/DateComponent';
import SideNav from './components/SideNav/SideNav.js';
import SatanSpeaks from './components/SatanSpeaks/SatanSpeaks.js';
import CategoryTodos from './components/CategoryTodos/CategoryTodos.js';
import ThemedTodoItem from './components/ThemedTodoItem/ThemedTodoItem';
import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher';
import Slideshow from './components/Slideshow/Slideshow';
import { ThemeProvider } from './components/ThemeContext/ThemeContext';
import Calendar from './components/Calendar/Calendar';
import CalendarTodos from './components/CalendarTodos/CalendarTodos';
import Upcoming from './components/Upcoming/Upcoming';
import Vault from './components/Vault/Vault';
import ScrollDetail from './components/ScrollDetail/ScrollDetail';
import Graveyard from './components/Graveyard/Graveyard';
import TombstoneContent from './components/TombstoneContent/TombstoneContent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAuth } from './AuthContext';
import SignUp from './components/SignUp/SignUp';
import SignIn from './components/SignIn/SignIn';
import Modal from './components/Modal/Modal';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import WelcomePage from './components/WelcomePage/WelcomePage';
import WeatherButton from './components/Weather/WeatherButton';

const App = () => {
  const { currentUser, updateUserData } = useAuth();
  const [todos, setTodos] = useState({});
  const [categories, setCategories] = useState([]);
  const [scrolls, setScrolls] = useState([]);
  const [theme, setTheme] = useState('default');
  const [newTodo, setNewTodo] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (currentUser) {
      setTodos(currentUser.todos || {});
      setCategories(currentUser.categories || []);
      setScrolls(currentUser.scrolls || []);
      setTheme(currentUser.theme || 'default');
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      updateUserData(currentUser.uid, { todos });
    }
  }, [todos, currentUser, updateUserData]);

  useEffect(() => {
    if (currentUser) {
      updateUserData(currentUser.uid, { categories });
    }
  }, [categories, currentUser, updateUserData]);

  useEffect(() => {
    if (currentUser) {
      updateUserData(currentUser.uid, { scrolls });
    }
  }, [scrolls, currentUser, updateUserData]);

  useEffect(() => {
    if (currentUser) {
      updateUserData(currentUser.uid, { theme });
    }
  }, [theme, currentUser, updateUserData]);

  useEffect(() => {
    const checkAndMoveTodos = () => {
      const today = new Date().toISOString().split('T')[0];
      const updatedTodos = { ...todos };

      if (todos[today]) {
        updatedTodos['My Day'] = [...(updatedTodos['My Day'] || []), ...updatedTodos[today]];
        delete updatedTodos[today];

        const updatedCategories = categories.map((cat) => {
          const count = updatedTodos[cat.name] ? updatedTodos[cat.name].length : 0;
          return { ...cat, count: count };
        });

        setTodos(updatedTodos);
        setCategories(updatedCategories);
      }
    };

    checkAndMoveTodos();
    const intervalId = setInterval(checkAndMoveTodos, 86400000); // Check daily

    return () => clearInterval(intervalId);
  }, [todos, categories]);

  const handleTodoUpdate = (updatedTodos) => {
    setTodos(updatedTodos);
  };

  const handleAddTodo = (category = null) => {
    if (newTodo.trim() !== '') {
      const newTodoItem = { text: newTodo, isCompleted: false, isStarred: false, tags: category ? [category] : [] };
      const date = category || 'My Day';
      const updatedTodos = { ...todos, [date]: [...(todos[date] || []), newTodoItem] };
      setTodos(updatedTodos);

      if (category) {
        const updatedCategories = categories.map(cat =>
          cat.name === category ? { ...cat, count: cat.count + 1 } : cat
        );
        setCategories(updatedCategories);
      }

      setNewTodo('');
      setShowInput(false);
    }
  };

  const handleIconClick = (category = null) => {
    setShowInput(true);
    setCurrentCategory(category);
  };

  const handleComplete = (date, index) => {
    const updatedTodos = { ...todos };
    updatedTodos[date][index].isCompleted = !updatedTodos[date][index].isCompleted;
    setTodos(updatedTodos);
  };

  const handleStar = (date, index) => {
    const updatedTodos = { ...todos };
    updatedTodos[date][index].isStarred = !updatedTodos[date][index].isStarred;
    setTodos(updatedTodos);
  };

  const handleDelete = (date, index) => {
    setTodos((prevTodos) => {
      const updatedTodos = { ...prevTodos };
      const todoToDelete = updatedTodos[date][index];

      // Remove the todo from the current list
      updatedTodos[date] = updatedTodos[date].filter((_, i) => i !== index);

      // Remove the todo from My Day if it's also there
      if (date !== 'My Day') {
        updatedTodos['My Day'] = updatedTodos['My Day'].filter(todo => todo.text !== todoToDelete.text);
      }

      // Remove the todo from the category if it's also there
      todoToDelete.tags.forEach(tag => {
        if (updatedTodos[tag]) {
          updatedTodos[tag] = updatedTodos[tag].filter(todo => todo.text !== todoToDelete.text);
        }
      });

      // Add the todo to the Graveyard (using the next day's date in user's time zone)
      const today = new Date();
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      const nextDayString = nextDay.toISOString().split('T')[0]; // Get the next day's date in YYYY-MM-DD format
      console.log(`Deleting todo on: ${nextDayString}`); // Debugging log

      if (!updatedTodos['Graveyard']) {
        updatedTodos['Graveyard'] = {};
      }
      if (!updatedTodos['Graveyard'][nextDayString]) {
        updatedTodos['Graveyard'][nextDayString] = [];
      }
      updatedTodos['Graveyard'][nextDayString].push(todoToDelete);

      // Decrement category count if necessary
      const updatedCategories = categories.map(cat => {
        if (todoToDelete.tags.includes(cat.name)) {
          return { ...cat, count: cat.count - 1 };
        }
        return cat;
      });
      setCategories(updatedCategories);

      return updatedTodos;
    });
  };

  const handleTag = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleSelectCategory = (todoIndex, category) => {
    const updatedTodos = { ...todos };
    const todoToAdd = { ...updatedTodos['My Day'][todoIndex] };

    // If the todo is not already tagged with the category, add the category tag
    if (!todoToAdd.tags.includes(category)) {
      todoToAdd.tags.push(category);

      // Add the todo to the category
      if (!updatedTodos[category]) {
        updatedTodos[category] = [];
      }
      updatedTodos[category].push(todoToAdd);

      // Update categories count
      const updatedCategories = categories.map(cat =>
        cat.name === category ? { ...cat, count: cat.count + 1 } : cat
      );
      setCategories(updatedCategories);

      // Update todos state
      setTodos(updatedTodos);
    }

    setDropdownIndex(null); // Close the dropdown after selection
  };

  const handleCategoryAdd = (newCategory) => {
    const updatedCategories = [...categories, { name: newCategory, count: 0 }];
    setCategories(updatedCategories);
  };

  const handleCategoryRename = (oldName, newName) => {
    const updatedCategories = categories.map(cat =>
      cat.name === oldName ? { ...cat, name: newName } : cat
    );
    setCategories(updatedCategories);

    const updatedTodos = {};
    Object.keys(todos).forEach(date => {
      if (date === oldName) {
        updatedTodos[newName] = todos[date];
      } else {
        updatedTodos[date] = Array.isArray(todos[date]) 
          ? todos[date].map(todo => {
              if (todo.tags.includes(oldName)) {
                return { ...todo, tags: todo.tags.map(tag => tag === oldName ? newName : tag) };
              }
              return todo;
            })
          : todos[date];
      }
    });
    setTodos(updatedTodos);
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowInput(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const resizeText = () => {
      const todos = document.querySelectorAll('.todo-text');
      todos.forEach(todo => {
        const containerWidth = todo.offsetWidth;
        const textWidth = todo.scrollWidth;
        const fontSize = parseFloat(window.getComputedStyle(todo).fontSize);

        if (textWidth > containerWidth) {
          const newFontSize = (containerWidth / textWidth) * fontSize * 0.9;
          todo.style.fontSize = newFontSize + 'px';
        } else {
          todo.style.fontSize = '';
        }
      });
    };

    window.addEventListener('resize', resizeText);
    document.addEventListener('DOMContentLoaded', resizeText);

    return () => {
      window.removeEventListener('resize', resizeText);
      document.removeEventListener('DOMContentLoaded', resizeText);
    };
  }, []);

  const handleHamburgerClick = () => {
    setShowSideNav(!showSideNav);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedTodos = Array.from(todos['My Day'] || []);
    const [removed] = reorderedTodos.splice(result.source.index, 1);
    reorderedTodos.splice(result.destination.index, 0, removed);
    const updatedTodos = { ...todos, 'My Day': reorderedTodos };
    handleTodoUpdate(updatedTodos);
  };

  const openModal = (isSignUp = false) => {
    setIsSignUp(isSignUp);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ThemeProvider value={{ theme, setTheme }}>
      <WeatherButton />
      <Router>
        <Slideshow />
        <div className="app-container">
          <SideNav
            isVisible={showSideNav}
            onClose={() => setShowSideNav(false)}
            onCategoryAdd={setCategories}
            onTodoUpdate={handleTodoUpdate}
            categories={categories}
            todos={todos}
            handleCategoryRename={handleCategoryRename}
            openModal={openModal}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <div className="my-day-container">
                    <h1 className="my-day">MY DAY</h1>
                  </div>
                  <DateComponent />
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="todos">
                      {(provided) => (
                        <div className='todo-content-container' {...provided.droppableProps} ref={provided.innerRef}>
                          {(todos['My Day'] || []).map((todo, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="draggable-todo"
                                >
                                  <ThemedTodoItem
                                    key={index}
                                    todo={todo}
                                    index={index}
                                    handleComplete={() => handleComplete('My Day', index)}
                                    handleStar={() => handleStar('My Day', index)}
                                    handleDelete={() => handleDelete('My Day', index)}
                                    handleTag={handleTag}
                                    dropdownIndex={dropdownIndex}
                                    categories={categories}
                                    handleSelectCategory={handleSelectCategory}
                                    handleCategoryAdd={handleCategoryAdd}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <footer className='footer-container'>
                    <div className='hamburger-icon' onClick={handleHamburgerClick}>
                      <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
                    </div>
                    <div className='add-todo-icon' onClick={() => handleIconClick(null)}>
                      <img className='add-todo-icon-image' src="/images/plus-sign.png" alt="plus-icon-footer-nav" />
                    </div>
                    <SatanSpeaks />
                  </footer>
                </>
              }
            />
            <Route
              path="/category/:categoryName"
              element={
                <ProtectedRoute>
                  <CategoryTodos
                    todos={todos}
                    categories={categories}
                    handleComplete={handleComplete}
                    handleStar={handleStar}
                    handleDelete={handleDelete}
                    handleHamburgerClick={handleHamburgerClick}
                    handleIconClick={handleIconClick}
                    handleAddTodo={handleAddTodo}
                    showInput={showInput}
                    setShowInput={setShowInput}
                    setNewTodo={setNewTodo}
                    handleTodoUpdate={handleTodoUpdate}
                    setCategories={setCategories}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <ProtectedRoute>
                  <Calendar handleHamburgerClick={handleHamburgerClick} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/calendar/:date"
              element={
                <ProtectedRoute>
                  <CalendarTodos
                    todos={todos}
                    handleTodoUpdate={handleTodoUpdate}
                    handleComplete={handleComplete}
                    handleStar={handleStar}
                    handleDelete={handleDelete}
                    handleHamburgerClick={handleHamburgerClick}
                    handleIconClick={() => handleIconClick(currentCategory)}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upcoming"
              element={
                <ProtectedRoute>
                  <Upcoming
                    todos={todos}
                    categories={categories} // Add this line
                    handleHamburgerClick={handleHamburgerClick}
                    handleTodoUpdate={handleTodoUpdate}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vault"
              element={
                <ProtectedRoute>
                  <Vault
                    scrolls={scrolls}
                    setScrolls={setScrolls}
                    categories={categories}
                    handleCategoryRename={handleCategoryRename}
                    todos={todos}
                    onTodoUpdate={handleTodoUpdate}
                    showSideNav={showSideNav}
                    handleHamburgerClick={handleHamburgerClick}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/scroll/:id"
              element={
                <ProtectedRoute>
                  <ScrollDetail 
                    scrolls={scrolls} 
                    categories={categories} 
                    todos={todos} 
                    handleCategoryRename={handleCategoryRename} 
                    handleTodoUpdate={handleTodoUpdate} 
                    showSideNav={showSideNav}
                    handleHamburgerClick={handleHamburgerClick}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/graveyard"
              element={
                <ProtectedRoute>
                  <Graveyard
                    todos={todos}
                    handleHamburgerClick={handleHamburgerClick}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/graveyard/:date"
              element={
                <ProtectedRoute>
                  <TombstoneContent
                    todos={todos}
                    handleTodoUpdate={handleTodoUpdate}
                    handleComplete={handleComplete}
                    handleStar={handleStar}
                    handleDelete={handleDelete}
                    handleHamburgerClick={handleHamburgerClick}
                  />
                </ProtectedRoute>
              }
            />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/welcome" element={<WelcomePage />} />
          </Routes>
          {showInput && currentCategory === null && (
            <div className="todo-input-container" ref={inputRef}>
              <input
                type="text"
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                className="todo-input"
                placeholder="Type your fate..."
              />
              <button onClick={() => handleAddTodo()} className="add-todo-button">Add</button>
            </div>
          )}
          <ThemeSwitcher />
        </div>
        <Modal show={isModalOpen} onClose={closeModal}>
          {isSignUp ? <SignUp /> : <SignIn />}
          <div>
            {isSignUp ? (
              <p>Already have an account? <button onClick={() => openModal(false)}>Sign In</button></p>
            ) : (
              <p>Don't have an account? <button onClick={() => openModal(true)}>Sign Up</button></p>
            )}
          </div>
        </Modal>
      </Router>
    </ThemeProvider>
  );
};

export default App;
